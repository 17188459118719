<par-form-field [class]="meta.class !== undefined ? meta.class: ''" [showErrors]="showError" [errorText]="errorText" [control]="control">
  <label [for]="meta.key" class="label">
    {{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>
  <input
    type="number"
    [name]="meta.key"
    [id]="meta.key"
    class="par-control par-input-w-[100%] disabled:text-black"
    [formControl]="control"
  />
</par-form-field>
