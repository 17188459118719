<par-form-field class="par-control-container" [showErrors]="showError" [errorText]="errorText" [control]="control">
  <label [for]="meta.key" class="label mr-3"
    >{{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>
  <input
    type="text"
    [id]="meta.key"
    [formControl]="control"
    placeholder="TT.MM.JJJJ"
    class="form-control par-control isa-text-bold par-control-btn"
    #dp="parInputDatepicker"
    parInputDatepicker
    autocomplete="off"
  />
  <button
    class="par-btn par-btn-input hover:text-primary-200 top-[1.4rem]"
    type="button"
    (click)="dp.toggle()"
    id="standout"
  >
    <span
      class="material-symbols-rounded par-svg text-3xl"
      [ngClass]="dp.isOpen ? 'iconActive' : ''"
      >calendar_month</span
    >
  </button>
  @if(control.dirty && control.invalid) {
    <div class="text-hrp-error-500 hrp-text-body-xs-bold">
      @for(error of control.errors | keyvalue; track error.key) {
        @switch (error.key) {
          @case ('required') {
            {{ meta.name }} ist ein Pflichtfeld.
          }
          @default {
            {{ error.value }}
          }
        }
        
      }
    </div>
  }
  
</par-form-field>
