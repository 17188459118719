import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '@paragondata/ngx-ui/datepicker';
import { KeyValuePipe, NgClass } from '@angular/common';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';

@Component({
  selector: 'dynamic-form-date-control',
  templateUrl: 'date-control.component.html',
  styleUrls: ['date-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    DatePickerModule,
    NgClass,
    UiFormFieldModule,
    KeyValuePipe
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormDateControlComponent,
    },
  ],
})
export class DynamicFormDateControlComponent extends AbstractDynamicFormControl {

}
