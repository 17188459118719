import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { SelectModule } from '@paragondata/ngx-ui/select';
import { DynamicFormControlOption } from '@shared/dynamic-form';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { NgFor } from '@angular/common';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';

@Component({
  selector: 'dynamic-form-multiselect-control',
  templateUrl: 'multiselect-control.component.html',
  styleUrls: ['multiselect-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
    SelectModule,
    NgFor,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormMultiselectControlComponent,
    },
  ],
})
export class DynamicFormMultiselectControlComponent extends AbstractDynamicFormControl {
  displayNameFn = (options: DynamicFormControlOption[]) => (selected: any) =>{
    return options?.find((o) => o.value === selected)?.key ?? selected;
  }

}
