<par-form-field [showErrors]="showError" [errorText]="errorText" [control]="control">
  <label [for]="meta.key" class="label">
    {{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>
  <par-select
    [id]="meta.key"
    [formControl]="control"
    [displayNameFn]="displayNameFn(meta.options)"
    (valueChange)="getValueChangeEvent({meta, event:$event})"
  >
    @for(option of meta.options; track option.key) {
    <par-select-option
      [value]="option.value"
      [disabled]="meta.options[option.key]?.disabled"
      [hidden]="meta.options[option.key]?.hidden"
    >
      <ng-container single>{{ option.key }}</ng-container>
    </par-select-option>
    }
  </par-select>
</par-form-field>
