import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';

@Component({
  selector: 'dynamic-form-styled-text-control',
  templateUrl: 'styled-text-control.component.html',
  styleUrls: ['styled-text-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormStyledTextControlComponent,
    },
  ],
})
export class DynamicFormStyledTextControlComponent extends AbstractDynamicFormControl {}
