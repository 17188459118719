<par-form-field [showErrors]="showError" [errorText]="errorText" [control]="control">
  <label [for]="meta.key" class="label">
    {{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>
  <par-select
    [id]="meta.key"
    [formControl]="control"
    [displayNameFn]="displayNameFn(meta.options)"
    [multiple]="true"
    [closable]="true"
    class="h-full"
  >
    <par-select-option
      itemMultiple
      [multiple]="true"
      [value]="option.value"
      *ngFor="let option of meta.options"
      ><ng-container multiple>{{ option.key }}</ng-container>
    </par-select-option>
  </par-select>
</par-form-field>
