<label
  class="grid grid-flow-col items-center justify-start gap-3 label"
  [for]="meta.key"
>
  <input
    class="par-control"
    type="checkbox"
    [name]="meta.key"
    [id]="meta.key"
    [formControl]="control"
  />
  <div>{{ meta.name }}{{ meta.validators?.required ? "*" : "" }}</div>
</label>
