import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { SelectModule } from '@paragondata/ngx-ui/select';
import { DynamicFormControlMetadata, DynamicFormControlOption } from '@shared/dynamic-form';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';

@Component({
  selector: 'dynamic-form-select-control',
  templateUrl: 'select-control.component.html',
  styleUrls: ['select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
    SelectModule,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormSelectControlComponent,
    },
  ],
})
export class DynamicFormSelectControlComponent extends AbstractDynamicFormControl {
  displayNameFn = (options: DynamicFormControlOption[]) => (selected: any) =>
    options?.find((o) => o.value === selected)?.key ?? selected;

  getValueChangeEvent({meta, event}:{meta:DynamicFormControlMetadata<any>; event:Event}) {
    const valueChangeAction = meta.actions?.find((a) => a.key === 'valueChange');
    if(event) {
      return valueChangeAction?.actionCallbackFn(event);
    }
  }
}
