import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';
import { TimeService } from '@shared/utils';


@Component({
  selector: 'dynamic-form-time-control',
  templateUrl: 'time-control.component.html',
  styleUrls: ['time-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormTimeControlComponent,
    },
  ],
})
export class DynamicFormTimeControlComponent extends AbstractDynamicFormControl {
  private timeService = inject(TimeService);
    timeIntervals = this.timeService.createTimeIntervals(30, { hours: 6, minutes: 0 }, { hours: 22, minutes: 0 });
    timeOptions = this.timeIntervals.map((time) => this.timeService.timeToString(time));

}
