import { ApplicationConfig, Type } from '@angular/core';
import { DynamicFormRegistry } from './dynamic-form.registry';
import { DynamicFormTextControlComponent } from './controls/text-control';
import { AbstractDynamicFormControl } from './abstact-dynamic-form-control.directive';
import { DynamicFormTextareaControlComponent } from './controls/textarea-control';
import { DynamicFormStyledTextControlComponent } from './controls/styled-text-control';
import { DynamicFormSelectControlComponent } from './controls/select-control';
import { DynamicFormRadioControlComponent } from './controls/radio-control';
import { DynamicFormMultiselectControlComponent } from './controls/multiselect-control';
import { DynamicFormDateControlComponent } from './controls/date-control';
import { DynamicFormCheckboxControlComponent } from './controls/checkbox-control';
import { DynamicFormNumberControlComponent } from './controls/number-control';
import { StyleControlComponent } from './custom-controls/style-control';
import { DynamicFormTimeControlComponent } from './controls/time-control';

export function provideDynamicForm(
  controls: Record<string, Type<AbstractDynamicFormControl>>
): ApplicationConfig['providers'] {
  return [
    {
      provide: DynamicFormRegistry,
      useFactory: () => {
        const registry = new DynamicFormRegistry();
        registerCustomControls({ controls, registry });
        registerDynamicFormControls(registry);
        return registry;
      },
    },
  ];
}

export function provideDynamicFormRoot(): ApplicationConfig['providers'] {
  return [
    {
      provide: DynamicFormRegistry,
      useFactory: () => {
        const registry = new DynamicFormRegistry();
        registerDynamicFormControls(registry);
        return registry;
      },
    },
  ];
}

function registerCustomControls({
  controls,
  registry,
}: {
  controls: Record<string, Type<AbstractDynamicFormControl>>;
  registry: DynamicFormRegistry;
}) {
  if (controls) {
    for (let key in controls) {
      registry.register(key, controls[key]);
    }
  }
}

function registerDynamicFormControls(registry: DynamicFormRegistry) {
  registry.register('text', DynamicFormTextControlComponent);
  registry.register('time', DynamicFormTimeControlComponent);
  registry.register('number', DynamicFormNumberControlComponent);
  registry.register('textarea', DynamicFormTextareaControlComponent);
  registry.register('styledText', DynamicFormStyledTextControlComponent);
  registry.register('select', DynamicFormSelectControlComponent);
  registry.register('radio', DynamicFormRadioControlComponent);
  registry.register('multiselect', DynamicFormMultiselectControlComponent);
  registry.register('date', DynamicFormDateControlComponent);
  registry.register('checkbox', DynamicFormCheckboxControlComponent);
  registry.register('style', StyleControlComponent);
}
