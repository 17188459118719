<ul class="style-list">
  @for(key of $keys(); track key) {
  <li>
    <button
      type="button"
      class="hover:bg-hrp-subtle-200"
      [class.active]="key === $selectedKey()"
      (click)="_$selectedKey.set(key)"
    >
      {{ key }}
    </button>
  </li>
  }
  <!-- <li>
      <button
        type="button"
        (click)="addKey()"
        class="grid grid-flow-col items-center justify-start text-hrp-primary-500"
      >
        <span class="material-symbols-rounded">add</span> Neu
      </button>
    </li> -->
</ul>

@for(control of formArray.controls; track control.value.key) {
@if(control.value.key === $selectedKey()) {

<div [formGroup]="control">
  <par-form-field [showRequiredMark]="!!meta.validators?.required">
    <label [for]="control.value.key + 'backgroundColor'" class="label">
      Hintergrundfarbe
    </label>
    <div class="par-control-container">
      <span class="material-symbols-rounded par-inner-prefix">tag</span>
      <input
        type="text"
        [name]="control.value.key + 'backgroundColor'"
        [id]="control.value.key + 'backgroundColor'"
        class="par-control par-text-body par-control-icon par-control-btn par-input-pr-16"
        formControlName="backgroundColor"
      />
    </div>
  </par-form-field>
  <par-form-field [showRequiredMark]="!!meta.validators?.required">
    <label [for]="control.value.key + 'textColor'" class="label"> Textfarbe </label>
    <div class="par-control-container">
      <span class="material-symbols-rounded par-inner-prefix">tag</span>
      <input
        type="text"
        [name]="control.value.key + 'textColor'"
        [id]="control.value.key + 'textColor'"
        class="par-control par-text-body par-control-icon par-control-btn par-input-pr-16"
        formControlName="textColor"
      />
    </div>
  </par-form-field>
  <par-form-field [showRequiredMark]="!!meta.validators?.required">
    <label [for]="control.value.key + 'borderColor'" class="label"> Rahmenfarbe </label>
    <div class="par-control-container">
      <span class="material-symbols-rounded par-inner-prefix">tag</span>
      <input
        type="text"
        [name]="control.value.key + 'borderColor'"
        [id]="control.value.key + 'borderColor'"
        class="par-control par-text-body par-control-icon par-control-btn par-input-pr-16"
        formControlName="borderColor"
      />
    </div>
  </par-form-field>
</div>

} }
