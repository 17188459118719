<par-form-field
  [showRequiredMark]="!! meta.validators?.required"
  [showErrors]="showError" [errorText]="errorText" [control]="control"
>
  <label [for]="meta.key" class="label">
    {{ meta.name }}
  </label>
  <div class="par-control-container">
    <span class="material-symbols-rounded par-inner-prefix">tag</span>
    <input
      type="text"
      [name]="meta.key"
      [id]="meta.key"
      [formControl]="control"
      class="par-control par-text-body par-control-icon par-control-btn par-input-pr-16"
    />
  </div>
</par-form-field>
