<par-form-field [class]="meta.class !== undefined ? meta.class: ''" [showRequiredMark]="!! meta.validators?.required" [showErrors]="showError" [errorText]="errorText" [control]="control">
  <label [for]="meta.key" class="label">{{ meta.name }}</label>
  <textarea
    cols="1"
    class="par-textarea h-full w-full my-1"
    [name]="meta.key"
    [id]="meta.key"
    [formControl]="control"
    autocomplete="off"
  ></textarea>
</par-form-field>
