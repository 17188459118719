
<div>
    <par-form-field
      [showRequiredMark]="!!meta.validators?.required"
      [showErrors]="showError" [errorText]="errorText" [control]="control"
    >
    <div>{{ meta.name }}{{ meta.validators?.required ? "*" : "" }}</div>
      <div class="grid grid-flow-col auto-cols-fr items-center">
        <ng-container *ngFor="let option of meta.options">
          <div class="flex flex-col items-center justify-between gap-3">
            <label [for]="option.key" class="label">
              {{ option.key }}
            </label>
            <input
              type="radio"
              [name]="meta.key"
              [id]="option.key"
              class="par-control"
              [formControl]="control"
              [value]="option.value"
            />
          </div>
        </ng-container>
      </div>
    </par-form-field>
  </div>
  