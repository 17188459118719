import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';


@Component({
    selector: 'dynamic-form-checkbox-control',
    templateUrl: 'checkbox-control.component.html',
    styleUrls: [ 'checkbox-control.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ DynamicFormControlTemplateDirective, ReactiveFormsModule ],
    providers: [{ provide: DynamicFormControlDirective, useExisting: DynamicFormCheckboxControlComponent}]
})
export class DynamicFormCheckboxControlComponent extends AbstractDynamicFormControl {

}