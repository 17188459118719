<par-form-field [class]="meta.class !== undefined ? meta.class : ''">
  <label [for]="meta.key" class="label">
    {{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>
  <input
    type="time"
    [name]="meta.key"
    [id]="meta.key"
    step="1800"
    class="par-control par-input-w-[100%] par-input-ptb-[0px] par-input-h-[32px] par-input-plr-2 hrp-text-body-bold disabled:text-black"
    [formControl]="control"
    [readOnly]="meta.readonly"
    list="time-options"
  />
  <datalist id="time-options">
    @for (timeOption of timeOptions; track $index) {
    <option [value]="timeOption"></option>
    }
  </datalist>
</par-form-field>
