import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { NgFor } from '@angular/common';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';

@Component({
    selector: 'dynamic-form-radio-control',
    templateUrl: 'radio-control.component.html',
    styleUrls: [ 'radio-control.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ DynamicFormControlTemplateDirective, ReactiveFormsModule, UiFormFieldModule, NgFor ],
    providers: [{ provide: DynamicFormControlDirective, useExisting: DynamicFormRadioControlComponent }]
})
export class DynamicFormRadioControlComponent extends AbstractDynamicFormControl {

}