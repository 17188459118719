import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
  signal,
} from '@angular/core';
import { DynamicFormControlMetadata } from './types';
import { FormControl } from '@angular/forms';
import { Subscription, distinctUntilChanged } from 'rxjs';

@Directive()
export abstract class AbstractDynamicFormControl<T = any> implements OnChanges {
  @Input('meta') 
  set meta(value: DynamicFormControlMetadata<T>) {
    this.$meta.set(value);
  }
  get meta() {
    return this.$meta();
  }

  $meta = signal<DynamicFormControlMetadata<T>>(null);

  @Input('control') set control(value: FormControl) { 
    this.$control.set(value);
  }
  get control() {
    return this.$control();
  }

  $control = signal<FormControl>(null);

  @Input('showError') showError: boolean = false;

  @Input('errorText') errorText: Record<string, string> = {};

  private cdr = inject(ChangeDetectorRef);

  private statusChangesSubscription: Subscription | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      this.statusChangesSubscription?.unsubscribe();
      this.statusChangesSubscription = this.control.statusChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          this.cdr.detectChanges();
        });
    }
  }
}
